// ブックの情報と操作ボタン

<template lang="pug">
.book-info.bg-white.border.px-4.py-3.text-gray-900(
  :class='`border-${colorMap.borderColor}`'
)
  .lg__flex.lg__items-center
    .icon.hidden.lg__block.pr-5(
      :class='`text-${colorMap.iconColor}`'
    )
      fa-icon(icon='book-open', size='2x')
    .summary.lg__flex-1
      h3.text-lg.font-bold {{ name }}
      .text-md
        | {{ organization }}
        template(v-if='type === "edit" || type === "cart"')
          span.mx-1 /
          span {{ child }} 様
      .text-sm.text-gray-700(v-if='specification') {{ specification }}
      .text-sm.text-gray-700(v-if='type === "recommended" || type === "other"')
        | 作成期限 : {{ createLimitAt | yyyyMMddHHmm }} まで
      .text-sm.text-gray-700(v-if='createdAt')
        | 作成日時 : {{ createdAt | yyyyMMddHHmm }}
      .text-sm.text-red-600(v-if='editLimitAt')
        | 編集期限 : {{ editLimitAt | yyyyMMddHHmm }} まで
    .operation.mt-3.lg__mt-0.text-center
      a.btn.text-white.py-3(
        v-if='type !== "cart"',
        :class='`bg-${colorMap.buttonColor}`',
        @click='$emit("clickCreateOrEdit")'
      )
        fa-icon(icon='edit')
        span.ml-1(v-if='type === "edit"') 編集再開
        span.ml-1(v-else) 作成してみる
      template(v-else)
        span 単価: {{ unitPrice }}円
        span.mx-1 &times;
        span
          select.select(
            :value='quantity',
            @change='$emit("updateQuantity", $event.target.value)'
          )
            option(v-for='num in quantities', :value='num') {{ num }}
          | 点

  .buttons.text-center.border-t.border-red-200.mt-3.pt-3(
    v-if='type === "cart"'
  )
    button.btn.p-1.bg-blue-500.text-white(
      @click='$emit("clickPreview")'
    ) 確認する
    button.btn.p-1.ml-3.bg-gray-200.font-normal(
      @click='$emit("clicRemove")'
    ) カゴから削除
</template>

<script>
import range from 'lodash.range'

const typeColorMaps = {
  recommended: {
    borderColor: 'orange-500',
    iconColor: 'orange-400',
    buttonColor: 'orange-500'
  },
  other: {
    borderColor: 'teal-500',
    iconColor: 'blue-300',
    buttonColor: 'blue-400'
  },
  edit: {
    borderColor: 'green-500',
    iconColor: 'green-400',
    buttonColor: 'green-600'
  },
  cart: {
    borderColor: 'red-300',
    iconColor: 'red-200'
  }
}

export default {
  name: 'BookInfo',

  props: {
    // typeは'recommended' or 'other' or 'edit' or 'cart'
    type: {
      type: String,
      required: true
    },
    // ブックの名前
    name: {
      type: String,
      required: true
    },
    organization: {
      type: String
    },
    child: {
      type: String
    },
    specification: {
      type: String
    },
    // 作成期限
    createLimitAt: {
      type: String
    },
    // 作成日時
    createdAt: {
      type: String
    },
    // 編集期限
    editLimitAt: {
      type: String
    },
    // 単価
    unitPrice: {
      type: Number
    },
    // 個数
    quantity: {
      type: Number
    }
  },

  computed: {
    colorMap () {
      return typeColorMaps[this.type]
    },
    quantities () {
      const max = parseInt(process.env.VUE_APP_MAX_QUANTITY_PER_ITEM, 10)
      return range(1, max + 1)
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
