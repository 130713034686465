// パブリックなホーム

<template lang="pug">
.public-home

  //-上側
  .upper.pt-10.px-3.pb-3.lg__pb-0.lg__flex.lg__flex-col.lg__justify-end

    //- タイトル
    h1.mx-auto.text-center.text-teal-800
      fa-icon(icon='book-open', size='2x')
      .mt-1.text-lg おもいでBook作成サイト

    .container.mx-auto.lg__flex

      //- アイキャッチ画像(PC用)
      .hidden.lg__block.lg__flex-2
        img(src='../../assets/book-image-top.png')

      //- 右側(PCの場合)
      .lg__flex-1.lg__self-center
        //- キャッチコピー
        .text-lg.text-center.mt-5.font-bold.text-orange-600
          | 1年間の思い出がいっぱいの
          br
          | あなただけの「おもいでBook」を作ってみよう!

        //- アイキャッチ画像(モバイル・タブレット用)
        .image.mx-auto.px-1.border-b.border-gray-300.lg__hidden
          img.block(
            src='../../assets/book-image-top.png'
          )

        //- ボタン等
        .login.mt-3
          a.block.text-center.p-2.border-b-2.border-orange-700.bg-orange-500.mx-auto.text-white.select-none.cursor-pointer(
            :href='psLoginEndpoint'
          )
            | 写真販売サイトPhotoSpotのIDで
            br
            span.text-2xl.font-bold.tracking-widest ログイン
          p.mt-3.text-sm.px-10.text-gray-700.text-center
            b 写真販売に利用しているIDとパスワード
            | でログインできます。
            | ログインすると作成可能な「おもいでBook」の一覧が表示されます。

  //- 下側
  .lower.relative.bg-teal-300.px-3.pt-4.pb-10.lg__py-6

    h2.text-center.text-teal-800.font-bold.text-xl.lg__text-3xl
      | 3ステップでカンタン作成
      fa-icon.ml-1(icon='music')

    .container.mx-auto.mt-4

      ul.lg__flex

        //- step 1
        li.step.flex.p-3.bg-red-200.rounded-lg.items-center.lg__bg-transparent.lg__flex-1
          //- 番号
          .pl-3.pr-6.font-bold.text-3xl.text-red-600.lg__hidden 1
          //- 説明
          .summary
            .bg-center.bg-no-repeat.lg__py-8.lg__px-4.lg__text-center
              p.hidden.lg__block.text-4xl.font-bold 1
              h3.text-lg.lg__mt-1.lg__text-2xl
                | 写真販売サイトのIDで
                br.hidden.lg__inline
                | ログイン
            p.mt-1.text-sm.text-gray-800.lg__mt-5.lg__text-base
              | 写真販売サイトPhotoSpotのIDとパスワードでログインします。

        li.arrow.mt-2.text-center.text-white.lg__flex.lg__items-center
          fa-icon(icon='arrow-down', size='2x')

        //- step 2
        li.mt-2.step.flex.p-3.bg-yellow-200.rounded-lg.items-center.lg__bg-transparent.lg__flex-1
          //- 番号
          .pl-3.pr-6.font-bold.text-3xl.text-yellow-600.lg__hidden 2
          //- 説明
          .summary
            .bg-center.bg-no-repeat.lg__py-8.lg__px-4.lg__text-center
              p.hidden.lg__block.text-4xl.font-bold 2
              h3.text-lg.lg__mt-1.lg__text-2xl
                | 作成するブックと
                br.hidden.lg__inline
                | 対象のお子様を選択
            p.mt-1.text-sm.text-gray-800.lg__mt-5.lg__text-base
              | 作成するブックを選んで、お子様のお名前やクラスを確認・修正します。

        li.arrow.mt-2.text-center.text-white.lg__flex.lg__items-center
          fa-icon(icon='arrow-down', size='2x')

        //- step 3
        li.mt-2.step.flex.p-3.bg-green-200.rounded-lg.items-center.lg__bg-transparent.lg__flex-1
          //- 番号
          .pl-3.pr-6.font-bold.text-3xl.text-green-600.lg__hidden 3
          //- 説明
          .summary
            .bg-center.bg-no-repeat.lg__py-8.lg__px-4.lg__text-center
              p.hidden.lg__block.text-4xl.font-bold 3
              h3.text-lg.lg__mt-1.lg__text-2xl
                | ブックが
                br.hidden.lg__inline
                | 自動生成されます!
            p.mt-1.text-sm.text-gray-800.lg__mt-5.lg__text-base
              | 自動生成されたブックは、写真を変更したり、文字を追加したりすることができます。

    //- バージョン
    p.p-1.absolute.left-0.bottom-0.right-0.text-right.text-blue-500 Ver.{{ version }}
</template>

<script>
export default {
  name: 'PublicHome',

  data () {
    return {
      version: process.env.VUE_APP_VERSION || '0.0.0'
    }
  },

  computed: {
    psLoginEndpoint () {
      return `${process.env.VUE_APP_API_BASE_URL}${process.env.VUE_APP_PS_LOGIN_PATH}`
    }
  }
}
</script>

<style lang="sass" scoped>
.public-home
  .upper, .lower
    min-height: 50vh
  .upper
    background: linear-gradient(#f0fcfd, #fff)
    h1
      text-shadow: 0 0 4px rgba(0,0,0,.3)
      svg
        filter: drop-shadow(0 0 4px rgba(0,0,0,.3))
    .image
      max-width: 350px
    .login
      a
        max-width: 400px
  .lower
    .step
      .summary
        > div
          +lg
            background-image: url(../../assets/circles-top.png)
            background-size: 90% 100%
    .arrow
      > *
        +lg
          transform: rotate(-90deg)
</style>
