import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'

import 'whatwg-fetch'

// fontawesome
import './fontawesome'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// 自前のプラグイン
import ModalPlugin from 'vue-modal-plugin'
import YbookSpreadViewer from 'ybook-spread-viewer'

// tailwind
import './assets/tailwind.css'

// ローディング
import 'vue-loading-overlay/dist/vue-loading.css'

import { parse as parseDate, format as formatDate } from 'date-fns'
import ja from 'date-fns/locale/ja'

// バリデーション
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, regex } from 'vee-validate/dist/rules'

import { checkNumbers as checkCreditCardNumbers } from './utils/credit-card'

// クレジットカード決済用
window.payjp = Payjp(process.env.VUE_APP_PAYJP_PUBLIC_KEY)

Vue.component('fa-icon', FontAwesomeIcon)

Vue.use(ModalPlugin)
Vue.use(YbookSpreadViewer)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
extend('required', {
  ...required,
  message: '必須項目です'
})
extend('regex', {
  ...regex,
  message: '入力内容が正しくありません。'
})
extend('creditcard', {
  validate: checkCreditCardNumbers,
  message: 'カード番号が正しくありません。'
})

// カスタムフィルタの追加
Vue.filter('yyyyMMddeee', value => {
  if (!value) return ''
  const d = parseDate(value, 'yyyy-MM-dd HH:mm:ss', new Date())
  return formatDate(d, 'yyyy/MM/dd (eee)', { locale: ja })
})
Vue.filter('yyyyMMddHHmm', value => {
  if (!value) return ''
  const d = parseDate(value, 'yyyy-MM-dd HH:mm:ss', new Date())
  return formatDate(d, 'yyyy/MM/dd HH:mm')
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
