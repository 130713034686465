// ブックのテンプレートの概要の取得

import api from '../api'

const path = '/book-templates/{id}/summary'

export default (bookTemplateId) => {
  return api({
    url: path.replace('{id}', bookTemplateId),
    auth: true
  })
}
