// エラーコード

export default {
  // 作成可能なブック情報の取得エラー
  GET_AVAILABLE_BOOK_TEMPLATE_ERROR: 'GET_AVAILABLE_BOOK_TEMPLATE_ERROR',

  // ブックテンプレートの概要取得エラー
  GET_BOOK_TEMPLATE_SUMMARY_ERROR: 'GET_BOOK_TEMPLATE_SUMMARY_ERROR',
  // ブックテンプレートからブックの作成エラー
  CREATE_BOOK_FROM_BOOK_TEMPLATE_ERROR: 'CREATE_BOOK_FROM_BOOK_TEMPLATE_ERROR',
  // ブックテンプレートで利用可能な購入済み写真の取得に失敗
  GET_BOOK_TEMPLATE_PURCHASED_PHOTOS_ERROR: 'GET_BOOK_TEMPLATE_PURCHASED_PHOTOS_ERROR',

  // フリー写真の取得に失敗
  GET_FREE_PHOTOS_ERROR: 'GET_FREE_PHOTOS_ERROR',

  // ユーザブックブックテンプレートからブックの作成エラー
  CREATE_BOOK_FROM_USER_BOOK_TEMPLATE_ERROR: 'CREATE_BOOK_FROM_USER_BOOK_TEMPLATE_ERROR',

  // レイヤーテンプレートのリストの取得エラー
  GET_LAYER_TEMPLATES_ERROR: 'GET_LAYER_TEMPLATES_ERROR',

  // スタンプの取得に失敗
  GET_STAMPS_ERROR: 'GET_STAMPS_ERROR',

  // テキストレンダリングサーバの情報取得に失敗
  GET_TEXT_RENDERER_INFO_ERROR: 'GET_TEXT_RENDERER_INFO_ERROR',

  // ブックの取得エラー
  GET_BOOK_ERROR: 'GET_BOOK_ERROR',
  // ブックの保存に失敗
  SAVE_BOOK_ERROR: 'SAVE_BOOK_ERROR',
  // 編集中のブックの一覧の取得に失敗
  GET_EDITABLE_BOOKS_ERROR: 'GET_EDITABLE_BOOKS_ERROR',
  // ブックの属性更新エラー
  UPDATE_BOOK_ATTRIBUTES_ERROR: 'UPDATE_BOOK_ATTRIBUTES_ERROR',

  // アップロード上限に達した
  UPLOAD_PHOTOS_LIMIT_ERROR: 'UPLOAD_PHOTOS_LIMIT_ERROR',
  // 何らかの理由でアップロードに失敗した
  UPLOAD_USER_PHOTO_ERROR: 'UPLOAD_USER_PHOTO_ERROR',
  // アップロード写真の取得に失敗した
  GET_UPLOAD_PHOTOS_ERROR: 'GET_UPLOAD_PHOTOS_ERROR',
  // アップロード済み写真の削除に失敗
  REMOVE_UPLOAD_PHOTO_ERROR: 'REMOVE_UPLOAD_PHOTO_ERROR',

  // カート情報の取得失敗
  GET_CART_ERROR: 'GET_CART_ERROR',
  // カートにアイテム追加失敗
  ADD_ITEM_TO_CART_ERROR: 'ADD_ITEM_TO_CART_ERROR',
  // カートのアイテム削除に失敗
  REMOVE_CART_ITEM_ERROR: 'REMOVE_CART_ITEM_ERROR',

  // 注文に必要なプロフィール取得に失敗
  GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',

  // カゴが空
  ORDER_TARGET_IS_EMPTY: 'ORDER_TARGET_IS_EMPTY',
  // セキュリティコードエラー
  CARD_SECURITY_CODE_INVALID: 'CARD_SECURITY_CODE_INVALID',
  // 有効期限エラー
  CARD_EXP_INVALID: 'CARD_EXP_INVALID',
  // 何らかの理由でのカードエラー
  CARD_INVALID: 'CARD_INVALID',
  // カード入力間違い
  CARD_DATA_INVALID: 'CARD_DATA_INVALID',
  // カード有効期限切れ
  CARD_EXPIRED: 'CARD_EXPIRED',
  // コンビニ決済エラー
  COMMIT_CONVENI_ERROR: 'COMMIT_CONVENI_ERROR',
  // 予期せぬ注文エラー
  UNKNOWN_ORDER_ERROR: 'UNKNOWN_ORDER_ERROR',
  // 注文情報の取得エラー
  GET_ORDER_INFO_ERROR: 'GET_ORDER_INFO_ERROR',
  // 注文履歴取得
  GET_ORDER_ITEMS_ERROR: 'GET_ORDER_ITEMS_ERROR',
  // 注文キャンセルエラー
  CANCEL_ORDER_ERROR: 'CANCEL_ORDER_ERROR'
}
