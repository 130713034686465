import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBookOpen,
  faTimes,
  faMusic,
  faArrowDown,
  faHome,
  faEdit,
  faClock,
  faShoppingCart,
  faDotCircle,
  faArrowLeft,
  faArrowRight,
  faSmile,
  faFont,
  faPalette,
  faThLarge,
  faUndo,
  faFileAlt,
  faPlayCircle,
  faBars,
  faMinus,
  faPlus,
  faCheck,
  faCloudUploadAlt,
  faCheckCircle,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'
import {
  faCircle
} from '@fortawesome/free-regular-svg-icons'

library.add(
  faBookOpen,
  faTimes,
  faMusic,
  faArrowDown,
  faHome,
  faEdit,
  faClock,
  faShoppingCart,
  faCircle,
  faDotCircle,
  faArrowLeft,
  faArrowRight,
  faSmile,
  faFont,
  faPalette,
  faThLarge,
  faUndo,
  faFileAlt,
  faPlayCircle,
  faBars,
  faMinus,
  faPlus,
  faCheck,
  faCloudUploadAlt,
  faCheckCircle,
  faQuestionCircle
)
