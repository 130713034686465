// スライドインして表示されるサイドメニュー

<template lang="pug">
transition(name='slide')
  .slide-menu.fixed.inset-0.z-30(
    :class='side',
    @click='$emit("close")'
  )
    //- clickイベントは、背景に伝播されないように
    .outer.absolute.bg-gray-200(
      @click.stop=''
    )

      ul
        li.header.bg-gray-400
          a.block.p-2.text-center.cursor-pointer(
            @click='$emit("close")'
          )
            fa-icon(icon='times', size='lg')
        li(v-for='item in items')
          a.block.px-2.py-3.text-center.border-b.border-gray-400.cursor-pointer(
            @click='$emit("clickItem", item.code)'
          )
            fa-icon.mr-2(v-if='item.icon', :icon='item.icon')
            | {{ item.title }}

      .mt-5.text-center.text-sm.text-blue-600
        router-link(
          :to='{ name: "PublicLaw" }'
        ) 特定商取引法に基づく表記
      .mt-3.text-center.text-sm.text-blue-600
        router-link(
          :to='{ name: "PublicPrivacy" }'
        ) 個人情報保護方針
</template>

<script>
export default {
  name: 'SideMenu',

  props: {
    items: {
      type: Array,
      required: true
    },
    side: {
      type: String,
      default: 'left'
    }
  }
}
</script>

<style lang="sass" scoped>
.slide-menu
  overflow-x: hidden
  background: rgba(0,0,0,0.5)
  transition: all .5s ease
  .outer
    width: 280px
    transition: all .3s ease
    min-height: 100%
  &.left
    .outer
      left: 0
    &.slide-enter, &.slide-leave-to
      background: rgba(0,0,0,0)
      .outer
        transform: translateX(-280px)
  &.right
    .outer
      right: 0
    &.slide-enter, &.slide-leave-to
      background: rgba(0,0,0,0)
      .outer
        transform: translateX(280px)
</style>
