// エディタ関連のストア

import get from 'lodash.get'
// プライグインによってインストールされたメソッドを利用したいため
import Vue from 'vue'

import getBookApi from '../api/books/get'
import getBookTemplatePurchasedPhotosApi from '../api/book-templates/get-purchased-photos'
import getStampsApi from '../api/stamps/get'
import getTextRendererInfoApi from '../api/misc/get-text-renderer-info'
import getLayerTemplatesApi from '../api/book-layer-templates/get'
import saveBookApi from '../api/books/save'
import getBookFreePhotosApi from '../api/books/get-free-photos'
import updateBookAttributesApi from '../api/books/update-attributes'
import addItemToCartApi from '../api/cart/add'
import spreadContentTransformer from '../utils/spread-content-transformer'
import getViewPort from '../utils/get-view-port'
import errorCodes from '../errors/codes'

const maxHistoryNum = parseInt(process.env.VUE_APP_MAX_HISTORY_NUM, 10) || 10

// ステート
const state = {
  // このブックのID
  bookId: null,
  // ブック本体
  bookBody: null,
  // ブックの情報
  bookInfo: null,
  // ユーザの属性値
  userAttributes: null,
  // 現在のページ
  // 0が表紙、1が裏表紙であることに注意
  currentPageIndex: 0,
  // 編集画面においてスプレッド全体表示をするか
  showEntireSpreadInEditor: true,
  // プレビュー画面においてスプレッド全体表示をするか
  showEntireSpreadInPreview: true,
  // 現在選択中のコンテントのID
  selectedContentId: null,
  // 利用可能な購入済みの写真
  purchasedPhotos: null,
  // フリー写真
  freePhotos: null,
  // スタンプの一覧
  stamps: null,
  // テキストレンダリングサーバの情報
  textRendererInfo: null,
  // レイヤテンプレート
  layerTemplates: {
    // 左右(通常)
    both: null,
    // 右のみ
    right: null,
    // 左のみ
    left: null
  },
  // ブックの変更が未保存かどうか
  isDirty: false,
  // 過去の編集情報,
  histories: [],
  // ヒストリが未プッシュかどうか
  // 読み込み時の初期状態はヒストリに保存されていないのでtrueになる
  isDirtyHistory: true,
  // プレビュー画面で説明を表示するかどうか
  showPreviewIntroModal: false,
  // 編集画面で説明を表示するかどうか
  showEditorIntroModal: false
}

// ミューテーション
const mutations = {
  // すべてクリア
  clear (state) {
    state.bookId = null
    state.bookBody = null
    state.bookInfo = null
    state.userAttributes = null
    state.currentPageIndex = 0
    state.showEntireSpreadInEditor = true
    state.showEntireSpreadInPreview = true
    state.selectedContentId = null
    state.purchasedPhotos = null
    state.freePhotos = null
    state.stamps = null
    state.textRendererInfo = null
    state.layerTemplates.both = null
    state.layerTemplates.right = null
    state.layerTemplates.left = null
    state.isDirty = false
    state.histories = []
    state.isDirtyHistory = true
    // 説明モーダルの表示状態についてはクリアしない
    // state.showPreviewIntroModal = false
    // state.showEditorIntroModal = false
  },
  setBookId (state, value) { state.bookId = value },
  setBookBody (state, value) { state.bookBody = value },
  setBookInfo (state, value) { state.bookInfo = value },
  setUserAttributes (state, value) { state.userAttributes = value },
  setCurrentPageIndex (state, value) { state.currentPageIndex = value },
  setShowEntireSpreadInEditor (state, value) { state.showEntireSpreadInEditor = value },
  setShowEntireSpreadInPreview (state, value) { state.showEntireSpreadInPreview = value },
  setSelectedContentId (state, value) { state.selectedContentId = value },
  setPurchasedPhotos (state, value) { state.purchasedPhotos = value },
  setFreePhotos (state, value) { state.freePhotos = value },
  setStamps (state, value) { state.stamps = value },
  setTextRendererInfo (state, value) { state.textRendererInfo = value },
  setBothLayerTemplate (state, value) { state.layerTemplates.both = value },
  setRightLayerTemplate (state, value) { state.layerTemplates.right = value },
  setLeftLayerTemplate (state, value) { state.layerTemplates.left = value },
  setIsDirty (state, value) { state.isDirty = value },
  // ヒストリ関連
  pushHistory (state, value) { state.histories.push(value) },
  popHistory (state) { return state.histories.pop() },
  shiftHistory (state) { return state.histories.shift() },
  clearHistory (state) { state.histories = [] },
  setIsDirtyHistory (state, value) { state.isDirtyHistory = value },
  setShowPreviewIntroModal (state, value) { state.showPreviewIntroModal = value },
  setShowEditorIntroModal (state, value) { state.showEditorIntroModal = value }
}

// ゲッタ
const getters = {
  // ブックの情報の取得が正常に完了しているか
  isReady (state) {
    return state.bookId && state.bookInfo && state.bookBody
  },
  bookId (state) { return state.bookId },
  // ブックの仕様のID
  bookSpecificationId (state) { return get(state.bookInfo, 'bookSpecificationId') },
  // ブックテンプレートのID
  bookTemplateId (state) { return get(state.bookInfo, 'bookTemplateId') },
  // ブックの元となったブックテンプレートの名前
  bookTemplateName (state) { return get(state.bookInfo, 'bookTemplateName', '') },
  // ユーザの属性値
  userAttributes (state) { return state.userAttributes },
  // ブックの綴じ方向
  bookBound (state) { return get(state.bookBody, 'specifications.bound') },
  // すべてのスプレッド
  spreads (state) { return get(state.bookBody, 'spreads', []) },
  // スプレッドの数
  spreadsNum (state) { return get(state.bookBody, 'spreads.length', 0) },
  // ページ数 = 見開き数の倍
  pagesNum (state, getters) { return getters.spreadsNum * 2 },
  // 現在のページ番号
  currentPageIndex (state) { return state.currentPageIndex },
  // 現在のスプレッド番号
  currentSpreadIndex (state) {
    return Math.floor(state.currentPageIndex / 2)
  },
  // 現在のスプレッド
  // bookBodyのspreadCommonをマージして返す
  currentSpread (state, getters) {
    if (!state.bookBody) return null
    return Vue.svGetSpread(state.bookBody, getters.currentSpreadIndex)
  },
  // 現在のスプレッドの幅と高さ
  currentSpreadWidth (state, getters) { return get(getters.currentSpread, 'width', 0) },
  currentSpreadHeight (state, getters) { return get(getters.currentSpread, 'height', 0) },
  // 現在のスプレッドのスコープ
  currentSpreadScope (state, getters) {
    return get(getters.currentSpread, 'scope', 'both')
  },
  // 現在のスプレッドの有効範囲
  currentSpreadMovableAreaRect (state, getters) {
    const offset = 10
    let x = offset
    let y = offset
    let width = getters.currentSpreadWidth - offset * 2
    let height = getters.currentSpreadHeight - offset * 2
    if (getters.currentSpreadScope === 'left') {
      width = getters.currentSpreadWidth / 2 - offset * 2
    } else if (getters.currentSpreadScope === 'right') {
      x = getters.currentSpreadWidth / 2 + offset
      width = getters.currentSpreadWidth / 2 - offset * 2
    }
    return { x, y, width, height }
  },
  // 現在のスプレッドが表紙または背表紙か
  currentSpreadIsCover (state) { return state.currentPageIndex < 2 },
  // 現在のスプレッドのすべてのレイヤをマージしたコンテント
  currentMergedContents (state, getters) {
    if (!state.bookBody) return []
    return Vue.svGetContentArray(
      state.bookBody,
      getters.currentSpreadIndex,
      spreadContentTransformer
    )
  },
  // 現在のスプレッドが背景色を変更可能か
  currentSpreadIsBackgroundChangeable (state, getters) {
    return get(getters.currentSpread, 'background.changeable', false)
  },
  // 現在のスプレッドがレイヤテンプレート変更可能かどうか
  currentSpreadIsLayerTemplateChangeable (state, getters) {
    const layers = get(getters.currentSpread, 'layers', [])
    const templateLayer = layers.find(item => item.type === 'template')
    return templateLayer ? !!templateLayer.changeable : false
  },
  // 現在のスプレッドにおいて利用可能なレイヤーテンプレート
  currentAvailableLayerTemplates (state, getters) {
    return get(state.layerTemplates, getters.currentSpreadScope, [])
  },
  // 選択中のコンテントのID
  selectedContentId (state) { return state.selectedContentId },
  // 選択中のコンテント
  selectedContent (state) {
    return Vue.svGetContent(state.bookBody, state.selectedContentId)
  },
  // 編集画面のビューポート
  // 左右表示か、右のみ、左のみか
  viewPortInEditor (state, getters) {
    return getViewPort(getters.bookBound, state.currentPageIndex, state.showEntireSpreadInEditor)
  },
  viewPortInPreview (state, getters) {
    return getViewPort(getters.bookBound, state.currentPageIndex, state.showEntireSpreadInPreview)
  },
  // 現在のビューポートにおける右上の座標
  // スタンプや文字列の追加等で利用する
  topLeftPoint (satete, getters) {
    // 右半分表示の時以外は(0,0)
    if (getters.viewPortInEditor !== 'right') return { x: 0, y: 0 }
    const width = get(getters.currentSpread, 'width', 0)
    const spine = get(getters.currentSpread, 'spine', 0)
    return {
      x: (width + spine) / 2,
      y: 0
    }
  },
  // 現在のスプレッドまたはページが最初かどうか
  currentPositionIsFirstInEditor (state, getters) {
    return getters.viewPortInEditor === 'both'
      // 見開き表示の場合
      ? getters.currentSpreadIndex === 0
      // 単ページ表示の場合はページ番号で判定する
      : state.currentPageIndex === 0
  },
  // 現在のスプレッドまたはページが最後かどうか
  currentPositionIsLastInEditor (state, getters) {
    return getters.viewPortInEditor === 'both'
      // 見開き表示の場合
      ? getters.currentSpreadIndex === getters.spreadsNum - 1
      : state.currentPageIndex === getters.pagesNum - 1
  },
  // 現在のスプレッドまたはページが最初かどうか
  currentPositionIsFirstInPreview (state, getters) {
    return getters.viewPortInPreview === 'both'
      // 見開き表示の場合
      ? getters.currentSpreadIndex === 0
      // 単ページ表示の場合はページ番号で判定する
      : state.currentPageIndex === 0
  },
  // 現在のスプレッドまたはページが最後かどうか
  currentPositionIsLastInPreview (state, getters) {
    return getters.viewPortInPreview === 'both'
      // 見開き表示の場合
      ? getters.currentSpreadIndex === getters.spreadsNum - 1
      : state.currentPageIndex === getters.pagesNum - 1
  },
  // スプレッドのリストの取得
  spreadList (state, getters) {
    return getters.spreads.map((spread, i) => {
      let title = spread.title
      if (!title) {
        title = i === 0 ? '表紙・背表紙' : `${i * 2 - 1} 〜 ${i * 2}ページ目`
      }
      return {
        name: title,
        index: i
      }
    })
  },
  // 購入済みの写真
  purchasedPhotos (state) {
    return state.purchasedPhotos
  },
  // フリー写真
  freePhotos (state) {
    return state.freePhotos
  },
  // 配置済み写真のチェックサム
  placedPhotosChecksums (state, getters) {
    if (!state.bookBody) return []
    let _checksums = []
    for (let i = 0; i < getters.spreadsNum; ++i) {
      // 各スプレッドのコンテントの配列を取得
      const items = Vue.svGetContentArray(state.bookBody, i)
      // 写真要素かつdataChecksumが存在するものからdataChecksumを抜き出す
      const checksums = items
        .filter(item => item.type === 'photo' && item.dataChecksum)
        .map(item => item.dataChecksum)
      _checksums = _checksums.concat(checksums)
    }
    return _checksums
  },
  // すべてのスタンプ
  stamps (state) {
    return state.stamps
  },
  textRendererBaseUrl (state) {
    return get(state.textRendererInfo, 'baseUrl', '')
  },
  // テキストレンダリングサーバで利用可能なフォントの一覧
  availableFonts (state) {
    return get(state.textRendererInfo, 'fonts', [])
  },
  // 変更が未保存かどうかのフラグ
  isDirty (state) {
    return state.isDirty
  },
  // ヒストリが存在するかどうか = アンドゥ可能か
  hasHistories (state) {
    return state.histories.length > 0
  },
  // プレビュー画面での説明表示フラグ
  showPreviewIntroModal (state) {
    return state.showPreviewIntroModal
  },
  // 編集画面での説明の表示フラグ
  showEditorIntroModal (state) {
    return state.showEditorIntroModal
  },
  // 属性値の候補の取得
  attributeEntries (state) {
    return get(state.bookBody, 'attributeEntries')
  }
}

// アクション
const actions = {
  // 初期化
  async init ({ commit, dispatch, getters }, { bookId, force = false }) {
    // 現在編集中のブックと異なるブックならストアを初期化
    if (this.bookId !== bookId) commit('clear')

    // すでに準備できていれば終了
    if (!force && getters.isReady) return

    try {
      // ページ単位表示かスプレッド単位表示かを判定
      dispatch('judgeShowEntireSpreadOrNot')
      // このブックのIDを保持
      commit('setBookId', bookId)
      // 必ず、先にテキストレンダリングサーバの情報を取得しておく
      // ブックに含まれるテキスト情報をURLに変換する際、この情報が必要なため
      await dispatch('getTextRendererInfo')
      await dispatch('getBook', force)
    } catch (e) {
      // 失敗時は再度ストアをクリア
      commit('clear')
      // 再スロー
      throw e
    }
  },
  // ブックの情報の取得
  async getBook ({ state, commit, getters }, force = false) {
    // すでに準備できていれば終了
    if (!force && getters.isReady) return

    const response = await getBookApi(state.bookId)
    if (!response.ok) {
      throw errorCodes.GET_BOOK_ERROR
    }

    const payload = response.payload
    // ストアに格納
    commit('setBookBody', payload.body)
    commit('setBookInfo', {
      bookSpecificationId: payload.bookSpecificationId,
      bookTemplateId: payload.bookTemplateId,
      bookTemplateName: payload.bookTemplateName,
      organizationName: payload.organizationName,
      price: payload.price,
      isFirstTime: payload.isFirstTime
    })
    commit('setUserAttributes', payload.attributes)
  },
  // 属性の強制更新
  async forceUpdateBookAttributes ({ state, commit, dispatch }, attributes) {
    // 準備できていないなら終了
    if (!getters.isReady) return
    // まず属性を更新
    const response = await updateBookAttributesApi({
      bookId: state.bookId,
      attributes
    })
    if (!response.ok) {
      throw { code: errorCodes.UPDATE_BOOK_ATTRIBUTES_ERROR }
    }
    // ストア属性値も更新
    commit('setUserAttributes', attributes)
    // 現在の内容の更新
    const body = Vue.svConcreteBookByAttributes(state.bookBody, attributes, true)
    commit('setBookBody', body)
    commit('setIsDirty', true)
    // 内容の保存
    await dispatch('saveBook')
  },
  // ブックの保存
  async saveBook ({ state, getters, commit }, silent = false) {
    // 準備できていないなら終了
    if (!getters.isReady) return
    // 保存の必要がなければ終了
    if (!state.isDirty) return

    const response = await saveBookApi({
      bookId: state.bookId,
      bookBody: state.bookBody,
      silent
    })
    // 保存失敗
    if (!response.ok) {
      throw { code: errorCodes.SAVE_BOOK_ERROR }
    }

    // 未保存フラグをクリア
    commit('setIsDirty', false)
  },
  // 利用可能な購入写真の情報の取得
  async getPurchasedPhotos ({ state, commit, getters }, { force = false, silent = false } = {}) {
    // ブックテンプレートのIDがなければ終了
    if (!getters.bookTemplateId) return
    // 既に取得済みか
    if (state.purchasedPhotos && !force) return

    const response = await getBookTemplatePurchasedPhotosApi({
      bookTemplateId: getters.bookTemplateId,
      silent
    })
    if (!response.ok) {
      throw { code: errorCodes.GET_BOOK_TEMPLATE_PURCHASED_PHOTOS_ERROR }
    }

    commit('setPurchasedPhotos', response.payload.items)
  },
  // フリー写真の取得
  async getFreePhotos ({ commit }, force = false) {
    // ブックのIDがなければ終了
    if (!state.bookId) return
    // 既に取得済みか
    if (state.freePhotos && !force) return

    const response = await getBookFreePhotosApi(state.bookId)
    if (!response.ok) {
      throw { code: errorCodes.GET_FREE_PHOTOS_ERROR }
    }

    commit('setFreePhotos', response.payload.items)
  },
  // スタンプの一覧の取得
  async getStamps ({ state, commit }) {
    // 既に取得済みなら終了
    if (state.stamps) return

    const response = await getStampsApi()
    if (!response.ok) {
      throw { code: errorCodes.GET_STAMPS_ERROR }
    }

    commit('setStamps', response.payload.items)
  },
  // テキストレンダリングサーバの情報の取得
  async getTextRendererInfo ({ state, commit }) {
    // 既に取得済みなら終了
    if (state.textRendererInfo) return

    const response = await getTextRendererInfoApi()
    if (!response.ok) {
      throw { code: errorCodes.GET_TEXT_RENDERER_INFO_ERROR }
    }

    commit('setTextRendererInfo', response.payload)
  },
  // 現在のスプレッド向けのレイヤーテンプレートの取得
  async getLayerTemplatesForCurrentSpread ({ state, getters, commit }) {
    const scope = getters.currentSpreadScope
    // すでに取得済みなら終了
    if (get(state.layerTemplates, scope)) return

    const response = await getLayerTemplatesApi({
      specId: getters.bookSpecificationId,
      scope
    })
    if (!response.ok) {
      throw errorCodes.GET_LAYER_TEMPLATES_ERROR
    }

    let destination = 'setBothLayerTemplate'
    if (scope === 'right') destination = 'setRightLayerTemplate'
    else if (scope === 'left') destination = 'setLeftLayerTemplate'
    commit(destination, response.payload.items)
  },
  // デバイスの情報から全体表示するかを判定する
  judgeShowEntireSpreadOrNot ({ commit, rootGetters }) {
    // スマホでないか、スマホでも縦位置でなければ全体表示する
    const showEntireSpread = !rootGetters['app/isSmartphone'] || rootGetters['app/isLandscapeDevice']
    commit('setShowEntireSpreadInEditor', showEntireSpread)
    commit('setShowEntireSpreadInPreview', showEntireSpread)
  },
  // 編集画面の表示モードをトグルする
  toggleShowEntireSpreadOrNotInEditor ({ state, commit }) {
    commit('setShowEntireSpreadInEditor', !state.showEntireSpreadInEditor)
  },
  toggleShowEntireSpreadOrNotInPreview ({ state, commit }) {
    commit('setShowEntireSpreadInPreview', !state.showEntireSpreadInPreview)
  },
  selectContent ({ commit }, contentId) {
    commit('setSelectedContentId', contentId)
  },
  unselectContent ({ commit }) {
    commit('setSelectedContentId', null)
  },
  // 次のスプレッドまたは、次のページへ
  goToNext ({ state, dispatch, commit, getters }, isPreview) {
    const showEntireSpread = isPreview ? state.showEntireSpreadInPreview : state.showEntireSpreadInEditor
    let pageIndex = getters.currentSpreadIsCover
      // 表紙裏表紙なら、次のページへ
      ? state.currentPageIndex + 1
      // それ以外は showEntireSpread に従う
      : state.currentPageIndex + (showEntireSpread ? 2 : 1)
    if (pageIndex >= getters.pagesNum) {
      pageIndex = getters.pagesNum - 1
    }
    // 移動前にアンセレクト
    dispatch('unselectContent')
    // ヒストリもクリア
    dispatch('clearHistory')
    commit('setCurrentPageIndex', pageIndex)
  },
  // 前のスプレッドまたは、前のページへ
  goToPrev ({ state, dispatch, commit, getters }, isPreview) {
    const showEntireSpread = isPreview ? state.showEntireSpreadInPreview : state.showEntireSpreadInEditor
    let pageIndex = state.currentPageIndex
    // 全体表示かどうか
    if (showEntireSpread) {
      // 見返しスプレッドなら、その前ページは裏表紙(ページのインデックス=1)とする
      if (getters.currentSpreadIndex === 1) pageIndex = 1
      else pageIndex -= 2
    } else {
      // シングルページ表示
      pageIndex -= 1
    }
    if (pageIndex < 0) pageIndex = 0
    // 移動前にアンセレクト
    dispatch('unselectContent')
    // ヒストリもクリア
    dispatch('clearHistory')
    commit('setCurrentPageIndex', pageIndex)
  },
  // 任意のスプレッドに移動
  jumpSpread ({ commit, dispatch, getters }, spreadIndex) {
    if (spreadIndex < 0 || spreadIndex >= getters.spreadsNum) return
    const pageIndex = spreadIndex * 2
    // 移動前にアンセレクト
    dispatch('unselectContent')
    // ヒストリもクリア
    dispatch('clearHistory')
    commit('setCurrentPageIndex', pageIndex)
  },
  // ブックの特定のスプレッドの特定のコンテンツを更新
  updateContent ({ state, dispatch }, { contentId, params }) {
    // bookのbodyがなければ終了
    if (!state.bookBody) return
    // 第4引数がtrueなら、写真要素変更時にアジャストする
    const body = Vue.svUpdateContent(state.bookBody, contentId, params, true)
    dispatch('updateBookBody', body)
  },
  // ブックの特定のスプレッドの特定のコンテントを削除
  removeContent ({ state, dispatch }, contentId) {
    // bookのbodyがなければ終了
    if (!state.bookBody) return
    const body = Vue.svRemoveContent(state.bookBody, contentId)
    dispatch('updateBookBody', body)
  },
  // ユーザによるコンテントの追加
  // 戻り値として追加したコンテントのIDを返す
  addUserContent ({ state, dispatch, getters }, content) {
    // bookのbodyがなければ終了
    if (!state.bookBody) return
    const { newBook, contentId } = Vue.svAddUserContent(state.bookBody, getters.currentSpreadIndex, content)
    dispatch('updateBookBody', newBook)
    return contentId
  },
  // 背景色の更新
  updatecurrentSpreadBackground ({ dispatch, getters, state }, background) {
    const body = Vue.svUpdateBackground(state.bookBody, getters.currentSpreadIndex, background)
    dispatch('updateBookBody', body)
  },
  // レイヤーテンプレートの変更
  changeLayerTemplate ({ dispatch, getters }, { templateId, contents }) {
    const body = Vue.svChangeTemplateLayerTemplate(
      state.bookBody,
      getters.currentSpreadIndex,
      templateId,
      contents,
      // 少し拡大しておく
      { photoScale: 1.05 }
    )
    dispatch('updateBookBody', body)
  },
  // アクション経由のブック本体の保存
  // この場合のみ、isDirtyフラグも更新する
  updateBookBody ({ commit }, body) {
    commit('setBookBody', body)
    commit('setIsDirty', true)
    commit('setIsDirtyHistory', true)
  },
  // 現在の状態をヒストリの追加
  pushHistory ({ state, commit }) {
    // すでに今の状態はpush済みなら終了
    if (!state.isDirtyHistory) return
    // 現在の状態をクローンしてヒストリに追加
    const body = JSON.parse(JSON.stringify(state.bookBody))
    commit('pushHistory', body)
    // 保持する最大のヒストリ件数を超えていたら、一番古いものを消去
    if (state.histories.length > maxHistoryNum) {
      commit('shiftHistory')
    }
    commit('setIsDirtyHistory', false)
  },
  // ヒストリをポップして反映 = アンドゥ
  popHistory ({ state, commit, dispatch }) {
    // 戻すべき状態がないならなにもしない
    if (state.histories.length === 0) return
    // ヒストリの末尾が戻すべき状態
    const body = state.histories[state.histories.length - 1]
    dispatch('updateBookBody', body)
    // 戻した状態をヒストリから削除
    commit('popHistory')
  },
  // ヒストリをクリア
  clearHistory ({ commit }) {
    commit('clearHistory')
  },
  // 現在のブックを保存してからカートに追加
  async saveAndAddToCart ({ state, commit, dispatch }) {
    if (!state.bookId) return
    // まずブックを保存
    await dispatch('saveBook')
    // カートに追加
    const response = await addItemToCartApi({ itemId: state.bookId })
    if (!response.ok) {
      throw { code: errorCodes.ADD_ITEM_TO_CART_ERROR }
    }
  },
  setShowPreviewIntroModal ({ commit }, value) {
    commit('setShowPreviewIntroModal', value)
  },
  setShowEditorIntroModal ({ commit }, value) {
    commit('setShowEditorIntroModal', value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
