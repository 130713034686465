// 特定のユーザブックテンプレートからブックを作成

import api from '../api'

const path = '/user-book-templates/{id}/book'

export default ({ id, attributes }) => {
  return api({
    method: 'post',
    url: path.replace('{id}', id),
    auth: true,
    params: { attributes }
  })
}
