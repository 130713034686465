import get from 'lodash.get'

import getMeApi from '../api/users/get-me'
import logoutApi from '../api/misc/logout'

// ステート
const state = {
  // ユーザ情報
  me: null,
  // ローディング中の作業の数
  loadings: 0,
  // 現在発生しているエラー
  error: null,
  // ブラウザの描画エリアのサイズ
  browserWidth: window.innerWidth,
  browserHeight: window.innerHeight
}

// ミューテーション
const mutations = {
  clear (state) {
    state.me = null
    state.loadings = 0
    state.error = null
  },
  setMe (state, value) { state.me = value },
  setLoadings (state, value) {
    if (value >= 0) state.loadings = value
  },
  // エラーコード
  setError (state, value) {
    state.error = value
  },
  setBrowserWidth (state, value) { state.browserWidth = value },
  setBrowserHeight (state, value) { state.browserHeight = value }
}

// ゲッタ
const getters = {
  // 名前
  name (state) { return get(state.me, 'name', '') },
  // ロール
  role (state) { return get(state.me, 'role', 'guest') },
  // ローディング中か
  isLoading (state) { return state.loadings > 0 },
  // 現在発生中のエラーのエラーコード
  error (state) { return state.error },
  // ブラウザの幅
  browserWidth (state) { return state.browserWidth },
  // ブラウザの高さ
  browserHeight (state) { return state.browserHeight },
  // 横長か
  isLandscapeDevice (state) { return state.browserWidth >= state.browserHeight },
  // スマートフォンかどうか
  isSmartphone (state) {
    return Math.min(state.browserWidth, state.browserHeight) < 640
  }
}

// アクション
const actions = {
  // ユーザ情報の取得
  async getMe ({ state, commit }, { force = false } = {}) {
    // すでにユーザ情報があるか
    if (state.me && !force) return
    const res = await getMeApi()
    if (res.ok) commit('setMe', res.payload)
  },
  // ログアウト
  async logout ({ state, commit }) {
    await logoutApi()
    // 情報をクリア
    commit('clear')
  },
  incrementLoadings ({ state, commit }) {
    commit('setLoadings', state.loadings + 1)
  },
  decrementLoadings ({ state, commit }) {
    commit('setLoadings', state.loadings - 1)
  },
  // ローディング状態をクリア
  clearLoadings ({ commit }) {
    commit('setLoadings', 0)
  },
  // エラーコードのセット
  setError ({ commit }, error) {
    commit('setError', error)
  },
  // エラーコードのクリア
  clearError ({ commit }) {
    commit('setError', null)
  },
  // ブラウザサイズの更新
  updateBrowserSize ({ state, commit }) {
    commit('setBrowserWidth', window.innerWidth)
    commit('setBrowserHeight', window.innerHeight)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
