// both、left、right(全体表示、左表示、右表示)を返す

module.exports = (bookBound, currentPageIndex, showEntireSpread) => {
  // ブックの閉じの逆
  const bookBoundInverse = bookBound === 'left' ? 'right' : 'left'
  // 表紙
  // なお、表紙・背表紙は強制的に単ページ表示
  if (currentPageIndex === 0) return bookBoundInverse
  // 背表紙
  else if (currentPageIndex === 1) return bookBound
  // 強制的に全体表示(PCの場合)
  else if (showEntireSpread) return 'both'
  // スマホ
  // ページが奇数 = 前ページ
  else if (currentPageIndex % 2) return bookBoundInverse
  else return bookBound
}
