// スプレッドのコンテントを適切に変換
// 現時点ではテキスト要素以外は何もしない

import map from 'lodash.map'
import pick from 'lodash.pick'

import store from '../store'

export default content => {
  if (content.type !== 'text') return content
  // テキストコンテントに、テキストレンダリングサーバのURLを付与
  const query = map(
    pick(content, ['font', 'fill', 'align']),
    (value, key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    }
  ).join('&')
  const textRendererUrl = store.getters['editor/textRendererBaseUrl']
  let url = `${textRendererUrl}/t/${encodeURIComponent(content.value)}`
  if (query) url += `?${query}`
  return {
    ...content,
    url
  }
}
