// エラーの詳細

import codes from './codes'

export default {
  [codes.GET_AVAILABLE_BOOK_TEMPLATE_ERROR]: {
    type: 'warning',
    message: '作成可能なブックの一覧の取得に失敗しました。'
  },
  [codes.GET_BOOK_TEMPLATE_SUMMARY_ERROR]: {
    type: 'warning',
    message: '指定されたブックの情報を取得できませんでした。'
  },
  [codes.CREATE_BOOK_FROM_BOOK_TEMPLATE_ERROR]: {
    type: 'warning',
    message: 'ブックの作成に失敗しました。'
  },
  [codes.GET_BOOK_TEMPLATE_PURCHASED_PHOTOS_ERROR]: {
    type: 'warning',
    message: '購入済み写真の情報を取得できませんでした。'
  },
  [codes.GET_FREE_PHOTOS_ERROR]: {
    type: 'warning',
    message: 'フリー写真の情報を取得できませんでした。'
  },
  [codes.CREATE_BOOK_FROM_USER_BOOK_TEMPLATE_ERROR]: {
    type: 'warning',
    message: 'ブックの作成に失敗しました。'
  },
  [codes.GET_LAYER_TEMPLATES_ERROR]: {
    type: 'warning',
    message: 'レイアウトパターンの取得に失敗しました。'
  },
  [codes.GET_STAMPS_ERROR]: {
    type: 'warning',
    message: 'スタンプの取得に失敗しました。'
  },
  [codes.GET_TEXT_RENDERER_INFO_ERROR]: {
    type: 'error',
    message: '文字表示のための情報を取得できませんでした。'
  },
  [codes.GET_BOOK_ERROR]: {
    type: 'warning',
    message: 'ブックの取得に失敗しました。'
  },
  [codes.SAVE_BOOK_ERROR]: {
    type: 'warning',
    message: 'ブックの保存に失敗しました。'
  },
  [codes.GET_EDITABLE_BOOKS_ERROR]: {
    type: 'warning',
    message: '現在編集中のブックの一覧の取得に失敗しました。'
  },
  [codes.UPDATE_BOOK_ATTRIBUTES_ERROR]: {
    type: 'warning',
    message: 'ブックの属性の更新に失敗しました。'
  },
  [codes.UPLOAD_PHOTOS_LIMIT_ERROR]: {
    type: 'warning',
    message: 'アップロード枚数の上限に達しました。'
  },
  [codes.UPLOAD_USER_PHOTO_ERROR]: {
    type: 'warning',
    message: 'アップロードに失敗しました。'
  },
  [codes.GET_UPLOAD_PHOTOS_ERROR]: {
    type: 'warning',
    message: 'アップロード済みの写真を取得できませんでした。'
  },
  [codes.REMOVE_UPLOAD_PHOTO_ERROR]: {
    type: 'warning',
    message: '写真の削除に失敗しました。'
  },
  [codes.GET_CART_ERROR]: {
    type: 'warning',
    message: '買い物カゴの情報の取得に失敗しました。'
  },
  [codes.ADD_ITEM_TO_CART_ERROR]: {
    type: 'warning',
    message: '買い物カゴに商品を追加できませんでした。'
  },
  [codes.REMOVE_CART_ITEM_ERROR]: {
    type: 'warning',
    message: '買い物カゴから商品を削除できませんでした。'
  },
  [codes.ORDER_TARGET_IS_EMPTY]: {
    type: 'error',
    message: '買い物カゴの中身が空です。購入したい商品を買い物カゴに追加してください。'
  },
  [codes.CARD_SECURITY_CODE_INVALID]: {
    type: 'info',
    message: 'クレジットカードのセキュリティコードが正しくありません。「内容を修正」を押して再確認してください。'
  },
  [codes.CARD_EXP_INVALID]: {
    type: 'info',
    message: 'クレジットカードの有効期限が正しくありません。「内容を修正」を押して再確認してください。'
  },
  [codes.CARD_INVALID]: {
    type: 'info',
    message: 'クレジットカード決済に失敗しました。「内容を修正」を押して再確認してください。'
  },
  [codes.CARD_DATA_INVALID]: {
    type: 'info',
    message: 'クレジットカードの入力情報が正しくありません。「内容を修正」を押して再確認してください。'
  },
  [codes.CARD_EXPIRED]: {
    type: 'info',
    message: 'クレジットカードが有効期限切れです。「内容を修正」を押して再確認してください。'
  },
  [codes.COMMIT_CONVENI_ERROR]: {
    type: 'info',
    message: 'コンビニ決済の処理に失敗しました。'
  },
  [codes.GET_PROFILE_ERROR]: {
    type: 'error',
    message: '注文に必用な情報を取得できませんでした。'
  },
  [codes.UNKNOWN_ORDER_ERROR]: {
    type: 'error',
    message: '注文の確定に失敗しました。誠にお手数ですが弊社(0561-55-4051 / 平日9:00〜18:00)までご連絡いただきますようお願い申し上げます。'
  },
  [codes.GET_ORDER_INFO_ERROR]: {
    type: 'warning',
    message: '注文の情報を取得できませんでした。'
  },
  [codes.GET_ORDER_ITEMS_ERROR]: {
    type: 'warning',
    message: '注文履歴の取得に失敗しました。'
  },
  [codes.CANCEL_ORDER_ERROR]: {
    type: 'warning',
    message: '注文のキャンセルに失敗しました。'
  },
  default: {
    type: 'error',
    message: '予期せぬエラーが発生しました。'
  }
}
