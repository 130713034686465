// アプリケーションのエントリポイント

<template lang="pug">
#app
  router-view

  //- ローディングスピナー
  loading-overlay(
    :active='isLoading',
    :z-index='1000'
  )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoadingOverlay from 'vue-loading-overlay'
import debounce from 'lodash.debounce'

import AlertMessageModal from './modals/alert-message'
import errorDetails from './errors/details'

export default {
  components: {
    LoadingOverlay
  },

  data () {
    return {
      debouncedUpdateBrowserSize: debounce(this.updateBrowserSize, 100)
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'app/isLoading',
      error: 'app/error'
    })
  },
  mounted () {
    // リサイズされたらブラウザサイズを更新
    window.addEventListener('resize', this.debouncedUpdateBrowserSize)
  },
  methods: {
    ...mapActions({
      clearLoadings: 'app/clearLoadings',
      clearError: 'app/clearError',
      // ブラウザサイズの更新
      updateBrowserSize: 'app/updateBrowserSize'
    })
  },
  watch: {
    error (e) {
      if (!e) return

      // ローディング解除
      this.clearLoadings()
      // ダイアログをすべて閉じる
      this.$forceCloseAllModal()

      const detail = e.code
        ? errorDetails[e.code] || errorDetails.default
        : errorDetails.default
      // // エラーについて表示
      this.$showModal(AlertMessageModal, false, {
        title: 'エラー',
        ...detail
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.debouncedUpdateBrowserSize)
  }
}
</script>

<style lang="sass">
html, body
  font-size: 14px
  +sm
    font-size: 16px
</style>
