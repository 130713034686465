// ブックの保存

import api from '../api'

const path = '/books/{id}'

export default ({
  bookId,
  bookBody,
  silent = false
}) => {
  return api({
    method: 'put',
    url: path.replace('{id}', bookId),
    params: { bookBody },
    auth: true,
    silent
  })
}
