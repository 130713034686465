import Vue from 'vue'
import VueRouter from 'vue-router'
import get from 'lodash.get'

import PublicHome from '../views/public/home'
import MyCommon from '../views/my/common'
import MyHome from '../views/my/home'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PublicHome',
    component: PublicHome,
    // memberであれば、マイページに移動するように
    beforeEnter: (to, from, next) => {
      if (store.getters['app/role'] === 'member') {
        next({ name: 'MyHome' })
      } else {
        next()
      }
    }
  },
  // 特定商取引法
  {
    path: '/law',
    name: 'PublicLaw',
    component: () => import('../views/public/law')
  },
  // 個人情報保護方針
  {
    path: '/privacy',
    name: 'PublicPrivacy',
    component: () => import('../views/public/privacy')
  },
  // マイページ
  {
    path: '/my',
    component: MyCommon,
    meta: { roles: ['member'] },
    children: [
      {
        path: '',
        name: 'MyHome',
        component: MyHome
      },
      {
        path: 'editables',
        name: 'MyEditables',
        component: () => import('../views/my/editables')
      },
      {
        path: 'orders',
        name: 'MyOrders',
        component: () => import('../views/my/orders')
      },
      // ブックの紹介
      {
        path: 'introduction',
        name: 'MyIntroduction',
        component: () => import('../views/my/introduction')
      }
    ]
  },
  // 編集関連
  {
    path: '/editor/:bookId',
    component: () => import('../views/editor/common'),
    children: [
      {
        path: '',
        name: 'EditorMain',
        component: () => import('../views/editor/main')
      },
      {
        path: 'preview',
        name: 'EditorPreview',
        component: () => import('../views/editor/preview')
      }
    ]
  },
  // 注文関連
  {
    path: '/order',
    component: () => import('../views/order/common'),
    children: [
      {
        path: 'cart',
        name: 'OrderCart',
        component: () => import('../views/order/cart')
      },
      // 注文情報入力フォーム
      {
        path: 'form',
        name: 'OrderForm',
        component: () => import('../views/order/form')
      },
      // 完了画面
      {
        path: ':orderCode/completed',
        name: 'OrderCompleted',
        component: () => import('../views/order/completed')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  // ユーザ情報の取得
  await store.dispatch('app/getMe')

  // 当該ページにアクセスする権限があるかをチェック
  const role = store.getters['app/role']
  for (let item of to.matched) {
    const requiredRoles = get(item, 'meta.roles', [])
    if (requiredRoles.length === 0) continue
    else if (requiredRoles.indexOf(role) === -1) {
      const fallback = get(item, 'meta.fallback', 'PublicHome')
      // fallbackが関数ならロールを渡した結果の戻り値を次の遷移先とする
      next({
        name: typeof fallback === 'function' ? fallback(role) : fallback
      })
      return
    }
  }

  next()
})

export default router
