// テキストレンダリングサーバの情報の取得

import api from '../api'

const path = '/misc/text-renderer-info'

export default () => {
  return api({
    url: path
  })
}
