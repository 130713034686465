// スタンプの取得

import api from '../api'

const path = '/stamps'

export default () => {
  return api({
    url: path,
    auth: true
  })
}
