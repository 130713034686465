// 作成再開可能なブックの一覧の取得

import api from '../api'

const path = '/users/editable-books'

export default () => {
  return api({
    url: path,
    auth: true
  })
}
