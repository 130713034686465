// アラート用の汎用ダイアログ

<template lang="pug">
modal-base.alert-message(
  size='s',
  :title='title',
  @clickClose='$closeModal(false)'
)
  p.p-3 {{ message }}

  template(v-slot:footer)
    .buttons.mt-3.p-3.text-center
      //- エラーの場合は再読込かマイページに戻る
      template(v-if='type === "error"')
        button.btn(
          @click='forceReload'
        ) 再読込する
        button.btn.ml-2(
          @click='goToMyPage'
        ) マイページへ
      template(v-else)
        button.btn(
          @click='$closeModal(true)'
        ) OK
</template>

<script>
import ModalBase from './modal-base'

export default {
  name: 'AlertMessage',

  components: {
    ModalBase
  },

  props: {
    title: {
      type: String
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String
    }
  },

  methods: {
    forceReload () {
      location.reload()
    },
    goToMyPage () {
      this.$closeModal()
      this.$router.push({ name: 'MyHome' })
    }
  }
}
</script>
