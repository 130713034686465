// 確認モーダル

<template lang="pug">
modal-base.confirm-message(
  size='s',
  :title='title',
  @clickClose='$closeModal(false)'
)
  p.p-3 {{ message }}

  template(v-slot:footer)
    .buttons.mt-3.p-3.text-center
      button.btn(
        @click='$closeModal(true)'
      ) はい
      button.btn.ml-3(
        @click='$closeModal(false)'
      ) いいえ
</template>

<script>
import ModalBase from './modal-base'

export default {
  name: 'ConfirmMessage',

  components: {
    ModalBase
  },

  props: {
    title: {
      type: String
    },
    message: {
      type: String,
      required: true
    }
  }
}
</script>
