// ブックレイヤーテンプレートのリストの取得

import api from '../api'

const path = '/book-layer-templates'

export default ({ specId, scope }) => {
  return api({
    url: `${path}?specId=${specId}&scope=${scope}`,
    auth: true
  })
}
