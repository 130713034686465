// カートにアイテムを指定した数量で追加

import api from '../api'

const path = '/cart'

export default ({
  // 現状はbookしかない
  itemTypeId = 'book',
  // 商品のID。こちらも現状bookのIDのみ利用可能
  itemId,
  // 数量
  quantity = 1
}) => {
  return api({
    url: path,
    auth: true,
    method: 'post',
    params: { itemTypeId, itemId, quantity }
  })
}
