// APIアクセス用のヘルパ

import omitBy from 'lodash.omitby'
import isNil from 'lodash.isnil'

import store from '../store'

export default async ({
  method = 'get',
  url,
  params,
  // 認証が必要か
  auth = false,
  // ローディング中にしないか
  silent = false
}) => {
  if (!silent) store.dispatch('app/incrementLoadings')

  const _url = /^https?:\/\//.test(url)
    ? url
    : (process.env.VUE_APP_API_BASE_URL || '') + url

  // paramsのうち、nullやundefinedは除去(falseは残す)
  const _params = params ? omitBy(params, isNil) : null
  const headers = {}
  if (_params) headers['Content-Type'] = 'application/json'

  // fetchの設定
  const options = {
    method,
    // 常にincludeはあまりよくなさそう
    credentials: 'include',
    body: _params ? JSON.stringify(_params) : null,
    headers
  }

  // 認証が必要ならcookieを付与
  if (auth) options.credentials = 'include'

  try {
    const res = await fetch(_url, options)
    const payload = await res.json()
    return {
      ok: res.ok,
      status: res.status,
      payload
    }
  } catch (e) {
    return {
      ok: false
    }
  } finally {
    if (!silent) store.dispatch('app/decrementLoadings')
  }
}
