// マイページのホーム

<template lang="pug">
.my-home
  section
    h2.text-3xl.font-bold.text-teal-700.lg__text-center おもいでBookって？
    p.mt-4.leading-loose.text-gray-700.lg__text-center
      b 昨年度
      | の購入写真や、ご自身で撮影したお写真を使用して作成する、あなただけのブックです。
      br
      | 詳しくは
      router-link.underline.cursor-ponter.text-blue-700.font-bold(
        :to='{ name: "MyIntroduction" }'
      ) こちらのページ
      | をご覧ください。
    .flex.justify-center.mt-4
      .px-3
        img.block.w-40.rounded-lg(src='../../assets/sample-mini-01.jpg')
      .px-3
        img.block.w-40.rounded-lg(src='../../assets/sample-mini-02.jpg')

  section.mt-12
    h2.text-3xl.font-bold.text-teal-700.lg__text-center おもいでBookを作る
    p.mt-2.leading-loose.text-gray-700.lg__text-center お好きな「おもいでBook」の「作成してみる」を押してください。

    .book-list.mx-auto.mt-5

      template(v-if='this.isReady && editableBooks.length > 0')
        h3.font-bold.text-lg 最近作成したブック
        ul.mt-5.mb-10
          li.mt-3.first__mt-0(v-for='item in editableBooks')
            book-info(
              type='edit',
              :name='item.bookTemplateName',
              :organization='item.organization',
              :specification='item.specification',
              :child='item.childName',
              :created-at='item.createdAt',
              :edit-limit-at='item.expiresAt',
              @clickCreateOrEdit='clickEditBook(item)'
            )

      h3.font-bold.text-lg 作成可能なブック一覧

      .mt-5
        p.bg-gray-200.p-3.text-center.text-gray-700(v-if='isReady && recommendedBooks.length === 0')
            | 現在作成可能なブックはありません。
            br
            br
            b 2021年度
            | のおもいでBookの作成をご希望の方は、お手数ですが下記までご連絡お願いいたします。
            br
            br
            | 写真の伊里
            br
            | E-mail : info@syashinnoiri.co.jp
            br
            | TEL : 0561-55-4051
        ul(v-else)
          li.mt-3.first__mt-0(v-for='item in recommendedBooks')
            book-info(
              type='recommended',
              :name='item.name',
              :organization='item.organization',
              :specification='item.specification',
              :create-limit-at='item.end',
              @clickCreateOrEdit='clickCreateBook(item)'
            )

      //- h3.font-bold.text-gray-700.mt-10 その他のブック

      //- .mt-5
      //-   p.bg-gray-200.p-3.text-center.text-gray-700(v-if='isReady && otherBooks.length === 0')
      //-     | 現在作成可能なブックはありません。
      //-   ul(v-else)
      //-     li.mt-3.first__mt-0(v-for='item in otherBooks')
      //-       book-info(
      //-         type='other',
      //-         :name='item.name',
      //-         :organization='item.organization',
      //-         :specification='item.specification',
      //-         :create-limit-at='item.end',
      //-         @clickCreateOrEdit='clickCreateBook(item)'
      //-       )
</template>

<script>
import { mapActions } from 'vuex'

import getEditableBooksApi from '../../api/users/get-editable-books'
import getAvailableBookTemplatesApi from '../../api/users/get-available-book-templates'
import errorCodes from '../../errors/codes'
import CreateBookModal from '../../modals/create-book'
import BookInfo from '../../components/book-info'

export default {
  name: 'MyHome',

  components: {
    BookInfo
  },

  data () {
    return {
      isReady: false,
      // オススメのブック
      recommendedBooks: [],
      // その他のブック
      otherBooks: [],
      // 作業中
      editableBooks: []
    }
  },

  async mounted () {
    await this.init()
    this.isReady = true
  },

  methods: {
    ...mapActions({
      setError: 'app/setError',
      setShowPreviewIntroModal: 'editor/setShowPreviewIntroModal',
      setShowEditorIntroModal: 'editor/setShowEditorIntroModal'
    }),

    // 初期化処理
    async init () {
      const [
        responseAvailable,
        responseEditables
      ] = await Promise.all([
        getAvailableBookTemplatesApi(),
        getEditableBooksApi()
      ])
      // 新規作成分
      if (!responseAvailable.ok) {
        this.setError({
          code: errorCodes.GET_AVAILABLE_BOOK_TEMPLATE_ERROR
        })
        return
      }
      this.recommendedBooks = responseAvailable.payload.recommended
      this.otherBooks = responseAvailable.payload.others
      // 作業中分
      if (responseEditables.ok) {
        this.editableBooks = responseEditables.payload.items.slice(0, 2)
      }
    },

    // ブックの作成ボタン押下
    async clickCreateBook (item) {
      const result = await this.$showModal(
        CreateBookModal,
        true,
        { bookTemplateId: item.id }
      )
      // 作成キャンセル、または失敗
      if (!result.ok) return
      // ユーザのブックテンプレートから作成の場合プレビュー画面へ
      if (result.obj.fromUserBookTemplate) {
        this.setShowPreviewIntroModal(true)
        this.$router.push({ name: 'EditorPreview', params: { bookId: result.obj.book.bookId } })
      } else {
        // 通常のブックテンプレートから作成の場合編集画面へ
        this.setShowEditorIntroModal(true)
        this.$router.push({ name: 'EditorMain', params: { bookId: result.obj.book.bookId } })
      }
    },

    // ブックの編集ボタン押下
    clickEditBook (item) {
      this.$router.push({
        name: 'EditorMain',
        params: { bookId: item.id }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.my-home

  .book-list
    max-width: 1000px
</style>
