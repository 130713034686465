// モーダルのベースとなるコンポーネント

<template lang="pug">
.modal-base.bg-white.self-center.flex.flex-col(
  :class='sizeClass'
)

  //- タイトルバー。タイトルが設定されていなければバー自体なし
  //- flex: noneにしておかないと、safariでmainが大きい時、シュリンクされてしまう
  .title-bar.bg-teal-300.flex.flex-none(v-if='title')
    h1.flex-grow.p-3 {{ title }}
    .close-button.flex.items-center.justify-center.bg-gray-300.px-6.py-2.select-none.cursor-pointer(
      v-if='showClose',
      @click='$emit("clickClose")'
    )
      fa-icon(icon='times')

  //- ヘッダ
  header
    slot(name='header')

  //- メインのコンテント
  main(ref='main')
    slot

  //- フッタ
  footer
    slot(name='footer')
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    showClose: {
      type: Boolean,
      default: true
    },
    // ダイアログのサイズ。s or m or l
    size: {
      type: String,
      default: 'm'
    }
  },

  computed: {
    sizeClass () {
      // h-screen(=100vh) にすると、safari等で表示がうまく行かない
      let hSizeClass = [ 'h-full', 'sm__h-auto' ]
      let wSizeClass = [ 'w-full', 'sm__max-w-xl' ]
      if (this.size === 's') {
        hSizeClass = [ 'h-auto' ]
        wSizeClass = [ 'w-4_5', 'sm__max-w-md' ]
      } else if (this.size === 'l') {
        wSizeClass = [ 'w-full', 'sm__max-w-xl', 'md__max-w-2xl', 'lg__max-w-4xl' ]
      }
      return [ ...hSizeClass, ...wSizeClass ]
    }
  },

  methods: {
    // メインのスロットのスクロール位置をリセットするために外部等から利用
    scrollMainToTop () {
      if (this.$refs && this.$refs.main) {
        this.$refs.main.scrollTop = 0
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.modal-base
  box-shadow: 0 3px 20px rgba(0,0,0,.08)
  main
    overflow-x: auto
    -webkit-overflow-scrolling: touch
    +sm
      max-height: 70vh
</style>
