// マイページの共通部分

<template lang="pug">
.my-common.lg__flex

  .sub.flex.items-center.bg-teal-300.lg__block.lg__px-3.lg__py-12.relative

    h1.flex-1.flex.items-center.ml-3.text-teal-800.lg__text-center.lg__block.lg__m-0.lg__border-b.lg__border-teal-500.lg__pb-3
      fa-icon(icon='book-open', size='2x')
      .ml-2.lg__text-lg おもいでBook作成サイト

    .hidden.login-info.mt-3.text-teal-800.text-center.lg__block
      .user {{ name }} 様 でログイン中
      .operations
        a.text-sm.underline.cursor-pointer(
          @click='logout'
        ) (ログアウト)

    ul.menu.hidden.lg__block.mt-10
      li
        router-link.block.rounded-full.bg-teal-200.text-center.text-lg.py-2.text-teal-800.select-none.cursor-pointer(
          :to='{ name: "MyHome" }'
        )
          fa-icon(icon='home')
          span.ml-2 マイページトップ
      li
        router-link.block.rounded-full.bg-teal-200.text-center.text-lg.py-2.text-teal-800.select-none.cursor-pointer(
          :to='{ name: "MyIntroduction" }'
        )
          fa-icon(icon='question-circle')
          span.ml-2 おもいでBookって？
      li
        router-link.block.rounded-full.bg-teal-200.text-center.text-lg.py-2.text-teal-800.select-none.cursor-pointer(
          :to='{ name: "MyEditables" }'
        )
          fa-icon(icon='edit')
          span.ml-2 作業中のブック
      li
        router-link.block.rounded-full.bg-teal-200.text-center.text-lg.py-2.text-teal-800.select-none.cursor-pointer(
          :to='{ name: "MyOrders" }'
        )
          fa-icon(icon='clock')
          span.ml-2 ご注文履歴
      li
        router-link.block.rounded-full.bg-teal-800.text-center.text-lg.py-2.text-white.select-none.cursor-pointer(
          :to='{ name: "OrderCart" }'
        )
          fa-icon(icon='shopping-cart')
          span.ml-2 カート

    p.hidden.absolute.inset-x-0.bottom-0.text-center.pb-8.leading-loose.text-sm.text-teal-800.lg__block
      router-link(
        :to='{ name: "PublicLaw" }'
      ) 特定商取引法に基づく表記
      br
      router-link(
        :to='{ name: "PublicPrivacy" }'
      ) 個人情報保護方針

    .menu-button.bg-teal-700.w-16.h-16.flex.items-center.justify-center.lg__hidden
      .bars.cursor-pointer.select-none(
        @click='showMenu = true'
      )
        .border-t-2.border-white.w-8
        .mt-2.border-t-2.border-white.w-8
        .mt-2.border-t-2.border-white.w-8

  .main.lg__flex-1
    .container.px-3.py-10.mx-auto.lg__px-6.lg__py-12
      router-view

  side-menu(
    v-show='showMenu'
    :items='sideMenuItems',
    side='right',
    @close='showMenu = false',
    @clickItem='clickSiteMenuItem'
  )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ConfirmMessageModal from '../../modals/confirm-message'
import SideMenu from '../../components/side-menu'

const sideMenuItems = [
  { title: 'マイページトップ', code: 'myhome' },
  { title: 'おもいでBookって？', code: 'introduction' },
  { title: '作業中のブック', code: 'editables' },
  { title: 'ご注文履歴', code: 'orders' },
  { title: '買い物かご', code: 'cart' }
]

export default {
  name: 'MyCommon',

  components: {
    SideMenu
  },

  data () {
    return {
      showMenu: false
    }
  },

  computed: {
    ...mapGetters({
      name: 'app/name'
    }),
    sideMenuItems () {
      return sideMenuItems
    }
  },

  methods: {
    ...mapActions({
      _logout: 'app/logout'
    }),
    clickSiteMenuItem (code) {
      this.showMenu = false
      if (code === 'myhome') {
        this.$router.push({ name: 'MyHome' })
      } else if (code === 'introduction') {
        this.$router.push({ name: 'MyIntroduction' })
      } else if (code === 'editables') {
        this.$router.push({ name: 'MyEditables' })
      } else if (code === 'orders') {
        this.$router.push({ name: 'MyOrders' })
      } else if (code === 'cart') {
        this.$router.push({ name: 'OrderCart' })
      }
    },
    // ログアウト
    async logout () {
      const result = await this.$showModal(ConfirmMessageModal, true, {
        title: '確認',
        message: 'ログアウトしますか？'
      })
      if (result.ok) {
        // 実際にログアウト
        await this._logout()
        // ホームへ遷移
        this.$router.push({ name: 'PublicHome' })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.my-common
  min-height: 100vh
  background: linear-gradient(#f0fcfd, #fff)
  .sub
    > h1
      text-shadow: 0 0 4px rgba(0,0,0,.2)
      svg
        filter: drop-shadow(0 0 4px rgba(0,0,0,.2))
    +lg
      width: 280px
    .menu
      li:nth-child(n+2)
        margin-top: 1.5rem
</style>
