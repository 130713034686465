// ブックの作成のモーダル

<template lang="pug">
modal-base.create-book(
  title='ブックの作成',
  @clickClose='$closeModal(false)'
)
  .book-detail.p-3

    h3.font-bold.text-gray-700 作成するおもいでBook

    table.border-collapse.mt-4.mx-auto
      tbody
        tr
          th 園・学校名
          td {{ organization }}
        tr
          th ブック名
          td {{ bookTemplateName }}
        tr
          th 種類
          td {{ specification }}
        tr
          th 価格
          td.leading-tight
            | {{ price }} 円
            span.text-xs.ml-1 (税別)
            br
            span.text-xs.text-green-800 ※別途送料が必要となります。
        tr
          th 作成開始期限
          td.leading-tight
            | {{ expiration | yyyyMMddHHmm }} まで
            br
            span.text-xs.text-xs.text-green-800 ※作成日から2週間は編集が可能です。

    .children-detail.mt-5
      h3.font-bold.text-gray-700 お子様の情報

      //- そのユーザ用のブックテンプレートがあるかどうかで表示を切り分け
      template(v-if='userBookTemplates')
        ul.children.mt-3.mx-auto
          li.border.border-gray-500.mt-3.first__mt-0.px-3.py-2.flex.items-center.select-none.cursor-pointer(
            v-for='item in userBookTemplates',
            :class='{ "selected": item.id === selectedUserBookTemplateId }',
            @click='selectedUserBookTemplateId = item.id'
          )
            .toggle.pr-2
              fa-icon(v-if='item.id !== selectedUserBookTemplateId', :icon='["far", "circle"]')
              fa-icon(v-else, icon='dot-circle')
            h4.flex-grow
              span {{ item.attributes.name }}
              span.text-sm.ml-1 様
            ul
              li.text-sm(v-for='attr in attributeEntries')
                | {{ attr.name }}:
                | {{ item.attributes[attr.key] || item.attributes.attributes[attr.key] || '' }}

      //- そのユーザ用のブック点プレートがない場合
      template(v-else)

        validation-observer(ref='observer')
          form.mx-auto.mt-3

            //- 名前は必須
            validation-provider(v-slot='{ errors, failed }', rules='required')
              .flex.items-center(:class='{ "failed": failed }')
                .left.w-24.text-right.mr-3
                  | お名前
                .right.flex-grow
                  input.input-text.block.w-full(
                    v-model.trim='form.name'
                    type='text'
                  )
                  .text-xs.mt-1.text-red-600 {{ errors[0] }}

            //- その他の属性値
            template(v-for='attr in attributeEntries')
              validation-provider(v-slot='{ errors, failed }', rules='required')
                .flex.items-center.mt-3(:class='{ "failed": failed }')
                  .left.w-24.text-right.mr-3
                    | {{ attr.name }}
                  .right.flex-grow
                    select.select.block.w-full(
                      v-model='form.attributes[attr.key]'
                    )
                      option(:value='undefined') 選択してください...
                      option(v-for='item in attr.candidates', :key='item') {{ item }}
                    .text-xs.mt-1.text-red-600 {{ errors[0] }}

  template(v-slot:footer)
    .mt-3.p-3.text-center
      button.btn.bg-orange-500.text-white.px-10(
        @click.prevent='clickCreate'
      )
        fa-icon(icon='edit')
        span.ml-1 スタート
</template>

<script>
import { mapActions } from 'vuex'
import get from 'lodash.get'

import ModalBase from './modal-base'

import bookTemplatesGetSummaryApi from '../api/book-templates/get-summary'
import userBookTemplatesCreateBookApi from '../api/user-book-templates/create-book'
import bookTemplatesCreateBookApi from '../api/book-templates/create-book'

import errorCodes from '../errors/codes'

export default {
  components: {
    ModalBase
  },

  props: {
    // 作成対象のブックテンプレートのID
    bookTemplateId: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      summary: null,
      selectedUserBookTemplateId: null,
      form: {
        name: null,
        attributes: {}
      }
    }
  },

  computed: {
    organization () { return get(this.summary, 'organization', '') },
    bookTemplateName () { return get(this.summary, 'name', '') },
    price () { return get(this.summary, 'price', '') },
    specification () { return get(this.summary, 'specification', '') },
    expiration () { return get(this.summary, 'end', '') },
    // このユーザのためのブックのテンプレート
    userBookTemplates () {
      const templates = get(this.summary, 'userBookTemplates', null)
      return templates
    },
    // 設定が必要な属性
    attributeEntries () { return get(this.summary, 'attributeEntries', []) }
  },

  async mounted () {
    const response = await bookTemplatesGetSummaryApi(this.bookTemplateId)
    if (!response.ok) {
      this.setError({
        code: errorCodes.GET_BOOK_TEMPLATE_SUMMARY_ERROR
      })
      return
    }
    this.summary = response.payload
    // ユーザ専用のブックテンプレートが存在するか
    if (this.userBookTemplates) {
      this.selectedUserBookTemplateId = this.userBookTemplates[0].id
    }
  },

  methods: {
    ...mapActions({
      setError: 'app/setError'
    }),

    // 作成開始ボタンが押された
    clickCreate () {
      if (this.userBookTemplates) this.createFromUserBookTemplate()
      else this.createFromBookTemplate()
    },

    // ユーザのブックテンプレートからのブック作成
    async createFromUserBookTemplate () {
      const template = this.userBookTemplates.find(item => item.id === this.selectedUserBookTemplateId)
      const response = await userBookTemplatesCreateBookApi({
        id: this.selectedUserBookTemplateId,
        attributes: {
          ...template.attributes,
          ...template.attributes.attributes
        }
      })
      // 作成に失敗したか
      if (!response.ok) {
        this.setError({
          code: errorCodes.CREATE_BOOK_FROM_USER_BOOK_TEMPLATE_ERROR
        })
        return
      }
      this.$closeModal(true, {
        book: response.payload,
        // ユーザのブックテンプレートから作成したかどうか
        fromUserBookTemplate: true
      })
    },

    // ブックテンプレートからのブックの作成
    async createFromBookTemplate () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        alert('入力に誤りがあります。')
        return
      }
      // 作成
      const response = await bookTemplatesCreateBookApi({
        id: this.bookTemplateId,
        attributes: {
          name: this.form.name,
          ...this.form.attributes
        }
      })
      if (!response.ok) {
        this.setError({
          code: errorCodes.CREATE_BOOK_FROM_BOOK_TEMPLATE_ERROR
        })
        return
      }
      this.$closeModal(true, {
        book: response.payload,
        // ユーザのブックテンプレートから作成したかどうか
        fromUserBookTemplate: false
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.create-book

  .book-detail
    table
      width: 100%
      max-width: 450px
      border-width: 1px 0
      border-color: #a1dde5
      th, td
        padding: 0.3rem 0.5rem
        border-bottom: 1px solid #a1dde5
      th
        font-size: 0.9rem
        color: #1697a8
        text-align: right

  .children-detail
    .children
      max-width: 450px
      > li.selected
        border-color: #e9a700
        border-width: 2px
        background: #fff9ea
        font-weight: bold
    form
      width: 100%
      max-width: 450px
</style>
