// 特定のブックの取得

import api from '../api'

const path = '/books/{id}'

export default id => {
  return api({
    url: path.replace('{id}', id),
    auth: true
  })
}
