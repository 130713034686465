// このテンプレートで利用可能な購入済み写真の取得

import api from '../api'

const path = '/book-templates/{id}/purchased-photos'

export default ({ bookTemplateId, silent = false }) => {
  return api({
    url: path.replace('{id}', bookTemplateId),
    auth: true,
    silent
  })
}
