// 属性値の更新

import api from '../api'

const path = '/books/{id}/attributes'

export default ({
  bookId,
  attributes
}) => {
  return api({
    method: 'put',
    url: path.replace('{id}', bookId),
    params: { attributes },
    auth: true
  })
}
