// ログアウト

import api from '../api'

const path = '/logout'

export default () => {
  return api({
    url: path
  })
}
