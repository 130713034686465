// 作成可能なブックの取得

import api from '../api'

const path = '/users/available-book-templates'

export default () => {
  return api({
    url: path,
    auth: true
  })
}
